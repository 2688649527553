import Service from '@/helpers/service'

export default {

    get(page, params) {
        return Service().get('CharacteristicMaster?page=' + page,  {params});
    }, 

    getCharacteristicByType(page, params) {
        return Service().get('CharacteristicMaster/characteristic-by-type?page=' + page, {params});
    },

    show(id) {
        return Service().get('CharacteristicMaster/show/'+id);
    },    
    create(params) {
        return Service().post('CharacteristicMaster/add_characteristic', params);
    },
    update(id, params) {
        return Service().post('CharacteristicMaster/update_characteristic/'+id, params);
    },
    delete(id) {
        return Service().post('CharacteristicMaster/delete_characteristic/'+id);
    },
    item_type(params) {
        return Service().get('CharacteristicMaster/item-type', {params});
    }, 
}