<template>
    <div>
        <div v-if="authUserPermission['characteristic-item-name-view']">
            <base-header class="pb-6">
                <div class="row align-items-center py-4">
                    <div class="col-lg-6 col-7">
                        <h6 class="h2 text-white d-inline-block mb-0"></h6>
                    </div>
                </div>
            </base-header>
            <div class="container-fluid mt--6">
                <div class="row">
                    <div class="col-md-6">
                        <div class="card mb-4">
                            <div class="card-header mt--4">
                                <div class="row">
                                    <!-- <div class="col-4">
                                        <h3 class="mb-0">{{ tt('characteristic') }}</h3>
                                    </div> -->
                                    <div class="col-5">
                                        <el-select v-model="table.search"
                                            :placeholder="tt('minimum_type_char', { total: 3 })"
                                            :remote-method="querySearchStandardItemName" :loading="loading" filterable
                                            remote reserve-keyword @change="filter()" style="margin-bottom: -15px">
                                            <el-option v-for="si in standardItemName" :key="si.inc"
                                                :label="si.inc + ' - ' + si.item_name" :value="si.inc"></el-option>
                                        </el-select>
                                    </div>
                                    <div class="col-7 text-right">
                                        <base-button v-if="authUserPermission['characteristic-item-name-create']"
                                            size="sm" type="default" @click="create" :disabled="!btnAdd">{{ tt('add')
                                            }}</base-button>
                                        <base-button v-if="authUserPermission['characteristic-item-name-update']"
                                            size="sm" type="default" @click="update" :disabled="!btnEdit">
                                            <span v-if="btnEditCin.onLoading"><i class="fas fa-spinner fa-spin"></i> {{
            tt('please_wait') }}</span>
                                            <span v-else>{{ tt('edit') }}</span>
                                        </base-button>
                                        <el-dropdown trigger="click" style="background-color:#172b4d">
                                            <span class="el-dropdown-link btn btn-sm text-white">
                                                {{ tt('filter') }}<i class="el-icon-arrow-down el-icon--right"></i>
                                            </span>
                                            <el-dropdown-menu slot="dropdown">
                                                <div class="container">
                                                    <p class="filter mb--1" @click="changeItemType('GEN')">GEN</p>
                                                    <p class="filter mb--1" @click="changeItemType('OEM')">OEM</p>
                                                </div>
                                            </el-dropdown-menu>
                                        </el-dropdown>
                                    </div>
                                </div>
                            </div>
                            <!-- view table characteristic -->
                            <div v-if="showTable">
                                <el-table height="500px" class="table-responsive table-flush"
                                    header-row-class-name="thead-light" :data="table.data" ref="singleTable"
                                    highlight-current-row v-if="!onLoad" @current-change="handleCurrentChange">
                                    <el-table-column :label="tt('characteristic')" :prop="tt('characteristic')"
                                        min-width="180px" sortable>
                                        <template v-slot="{ row }">
                                            {{ row.characteristic }}
                                        </template>
                                    </el-table-column>
                                    <el-table-column :label="tt('item_type')" :prop="tt('item_type')" min-width="140px"
                                        sortable>
                                        <template v-slot="{ row }">
                                            {{ row.item_type_code }}
                                        </template>
                                    </el-table-column>
                                    <el-table-column :label="tt('sequence')" :prop="tt('sequence')" min-width="140"
                                        sortable>
                                        <template v-slot="{ row }">
                                            <input type="text" class="form-control form-control-sm" aria-label="Small"
                                                aria-describedby="inputGroup-sizing-sm"
                                                v-model="table.data[table.data.map(function (d) { return d['cin_code']; }).indexOf(row.cin_code)].sequence">
                                        </template>
                                    </el-table-column>
                                    <el-table-column :label="tt('seq_shortdes')" :prop="tt('seq_shortdes')"
                                        min-width="170px" sortable>
                                        <template v-slot="{ row }">
                                            <input type="text" class="form-control form-control-sm" aria-label="Small"
                                                aria-describedby="inputGroup-sizing-sm"
                                                v-model="table.data[table.data.map(function (d) { return d['cin_code']; }).indexOf(row.cin_code)].sequence_shortdes">
                                        </template>
                                    </el-table-column>
                                    <el-table-column :label="tt('separator')" :prop="tt('separator')" min-width="140">
                                        <template v-slot="{ row }">
                                            <select name="" id="" class="form-control form-control-sm"
                                                v-model="table.data[table.data.map(function (d) { return d['cin_code']; }).indexOf(row.cin_code)].separator_code">
                                                <option value=""></option>
                                                <option value=";">;</option>
                                                <option value="X">X</option>
                                                <option value="-">-</option>
                                                <option value="/">/</option>
                                                <option value=":">:</option>
                                                <option value="+">+</option>
                                            </select>
                                        </template>
                                    </el-table-column>
                                    <el-table-column :label="tt('is_mandatory')" :prop="tt('is_mandatory')"
                                        min-width="140">
                                        <template v-slot="{ row }">
                                            <select name="" id="" class="form-control form-control-sm"
                                                v-model="table.data[table.data.map(function (d) { return d['cin_code']; }).indexOf(row.cin_code)].is_mandatory">
                                                <option value="yes">Yes</option>
                                                <option value="no">No</option>
                                            </select>
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="action" width="70">
                                        <template v-slot="{ row }">
                                            <i v-if="authUserPermission['characteristic-item-name-delete']"
                                                class="fa fa-trash text-danger" @click="remove(row.cin_code)"></i>
                                        </template>
                                    </el-table-column>
                                </el-table>
                                <skeleton-loading v-else />
                            </div>
                            <div v-else
                                style="height: 500px;display: flex;justify-content: center;align-items: center;flex-direction: column">
                                <img src="/assets/smartcat/data_is_empty.png" alt="Data Is Empty" height="320px"
                                    width="340px">
                                <p class="text-muted">{{ tt('please_search_to_show_data') }}</p>
                            </div>
                            <div class="card-footer pb-0 ">
                                <span class="float-left">
                                    <base-pagination :page-count="table.page.last_page"
                                        v-model="table.page.current_page"
                                        @click.native="changePage(table.page.current_page)" size="sm"></base-pagination>
                                </span>
                                <span class="float-right">
                                    {{ tt('page_info', {
            size: (table.page.current_page - 1) * table.page.per_page +
                table.data.length, total:
                table.total
        }) }}
                                </span>
                            </div>
                        </div>
                    </div>

                    <!-- VIEW CHAR VALUE -->
                    <div class="col-md-6">
                        <div class="card">
                            <div class="card-header mt--4 mb--4">
                                <div class="row">
                                    <div class="col-3">
                                        <h3 class="mb-0">{{ tt('char_value') }}</h3>
                                    </div>
                                    <div class="col-6">
                                        <base-input input-classes="form-control-sm text-center border-radius-20"
                                            v-model="tableCharValue.search" :placeholder="tt('search_sample_answer')"
                                            v-on:keyup="filterCharValue" :disabled="!btnAddCharValue" />
                                    </div>
                                    <div class="col-3 text-right">
                                        <base-button size="sm" type="default" @click="createCharValue"
                                            :disabled="!btnAddCharValue"
                                            v-if="authUserPermission['characteristic-item-name-create-char-value']">{{
            tt('add')
        }}</base-button>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <el-table height="500px" class="table-responsive table-flush"
                                    header-row-class-name="thead-light" :data="tableCharValue.data"
                                    v-if="!onLoadCharValue">
                                    <el-table-column :label="tt('char_value')" :prop="tt('char_value')"
                                        min-width="200px" sortable>
                                        <template v-slot="{ row }">
                                            {{ row.characteristic_value }}
                                        </template>
                                    </el-table-column>
                                    <el-table-column :label="tt('abbrev')" :prop="tt('abbrev')" min-width="200px"
                                        sortable>
                                        <template v-slot="{ row }">
                                            {{ row.abbreviation }}
                                        </template>
                                    </el-table-column>
                                    <el-table-column :label="tt('status')" :prop="tt('status')" min-width="140px"
                                        sortable>
                                        <template v-slot="{ row }">
                                            {{ (row.approved == 1) ? 'Approved' : 'Not Approved' }}
                                        </template>
                                    </el-table-column>
                                    <el-table-column :label="tt('count')" :prop="tt('count')" min-width="120px"
                                        sortable>
                                        <template v-slot="{ row }">
                                            {{ row.eu_part_characteristic_value.length }}
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="action" width="100">
                                        <template v-slot="{ row }">
                                            <el-dropdown trigger="click" class="dropdown" @command="handleTableAction"
                                                :hide-on-click="true"
                                                v-if="authUserPermission['characteristic-item-name-update-char-value'] || authUserPermission['characteristic-item-name-delete-char-value']">
                                                <span class="btn btn-sm btn-icon-only text-light">
                                                    <i class="fas fa-ellipsis-v mt-2"></i>
                                                </span>
                                                <el-dropdown-menu class="dropdown-menu dropdown-menu-arrow show"
                                                    slot="dropdown">
                                                    <el-dropdown-item
                                                        :command="{ action: 'editCharValue', data: tableCharValue.data.map(function (d) { return d['characteristic_value']; }).indexOf(row.characteristic_value) }"
                                                        v-if="authUserPermission['characteristic-item-name-update-char-value']">{{
            tt('edit') }}</el-dropdown-item>
                                                    <el-dropdown-item
                                                        :command="{ action: 'removeCharValue', data: row.id }"
                                                        v-if="authUserPermission['characteristic-item-name-delete-char-value']">{{
            tt('delete') }}</el-dropdown-item>
                                                </el-dropdown-menu>
                                            </el-dropdown>
                                        </template>
                                    </el-table-column>
                                </el-table>
                                <skeleton-loading v-else />
                            </div>
                            <div class="card-footer pb-0 ">
                                <span class="float-left">
                                    <base-pagination :page-count="tableCharValue.page.last_page"
                                        v-model="tableCharValue.page.current_page"
                                        @click.native="changePageCharValue(tableCharValue.page.current_page)"
                                        size="sm"></base-pagination>
                                </span>
                                <span class="float-right">
                                    {{ tt('page_info', {
            size: (tableCharValue.page.current_page - 1) *
                tableCharValue.page.per_page +
                tableCharValue.data.length, total: tableCharValue.total
        }) }}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- MODAL ADD CHARACTERISTIC -->
            <validation-observer v-slot="{ invalid }">
                <modal :show.sync="form.show">
                    <template slot="header">
                        <h5 class="modal-title">{{ form.title }}</h5>
                    </template>
                    <div>
                        <base-alert type="danger" v-if="errorMessage">
                            <p><strong>{{ errorMessage.message }}</strong></p>
                            <div v-if="errorMessage.data.length != 0">
                                <span v-for="message in errorMessage.data" :key="message">
                                    {{ message[0] }}<br>
                                </span>
                            </div>
                        </base-alert>

                        <label class="form-control-label">{{ tt('characteristic_master') }} <span
                                class="text-danger">*</span></label>
                        <div class="row mb-4">
                            <el-select class="inline-input col-md-12"
                                v-model="characteristicItemName.characteristic_code"
                                :placeholder="tt('choose_characteristic_master')"
                                :remote-method="querySearchCharacteristicMaster" :loading="loading" filterable remote
                                reserve-keyword size="large">
                                <el-option v-for="sc in selectCharacteristic" :key="sc.characteristic_code"
                                    :label="sc.characteristic + ' (' + sc.item_type_code + ')'"
                                    :value="sc.characteristic_code"></el-option>
                            </el-select>
                        </div>

                        <span class="row">
                            <span class="col-6">
                                <label class="form-control-label">{{ tt('sequence') }} <span
                                        class="text-danger">*</span></label>
                                <base-input :name="tt('sequence')" :placeholder="tt('sequence')"
                                    rules="required|numeric" v-model="characteristicItemName.sequence"
                                    type="number"></base-input>
                            </span>

                            <span class="col-6">
                                <label class="form-control-label">{{ tt('seq_shortdes') }} <span
                                        class="text-danger">*</span></label>
                                <base-input :name="tt('seq_shortdes')" rules="required|numeric"
                                    :placeholder="tt('seq_shortdes')" v-model="characteristicItemName.seq_shortdes"
                                    type="number"></base-input>
                            </span>
                        </span>

                        <span class="row">
                            <span class="col-6">
                                <label class="form-control-label">{{ tt('separator') }} <span
                                        class="text-danger">*</span></label>
                                <base-input :name="tt('separator')" rules="required">
                                    <el-select class="select-danger" v-model="characteristicItemName.separator"
                                        :placeholder="tt('choose_separator')">
                                        <el-option class="select-danger" value=";" label=";" key=";"></el-option>
                                        <el-option class="select-danger" value="X" label="X" key="X"></el-option>
                                        <el-option class="select-danger" value="-" label="-" key="-"></el-option>
                                        <el-option class="select-danger" value="/" label="/" key="/"></el-option>
                                        <el-option class="select-danger" value=":" label=":" key=":"></el-option>
                                        <el-option class="select-danger" value="+" label="+" key="+"></el-option>
                                    </el-select>
                                </base-input>
                            </span>
                            <span class="col-6">
                                <label class="form-control-label">{{ tt('is_mandatory') }} <span
                                        class="text-danger">*</span></label>
                                <base-input :name="tt('is_mandatory')" rules="required">
                                    <el-select class="select-danger" v-model="characteristicItemName.is_mandatory"
                                        :placeholder="tt('choose_is_mandatory')">
                                        <el-option class="select-danger" value="yes" label="Yes"></el-option>
                                        <el-option class="select-danger" value="no" label="No"></el-option>
                                    </el-select>
                                </base-input>
                            </span>
                        </span>

                        <br>
                    </div>
                    <template slot="footer">
                        <base-button type="secondary" @click="form.show = false">{{ tt('close') }}</base-button>
                        <base-button type="primary" v-on:click="save" :disabled="btnSave.onLoading || invalid">
                            <span v-if="btnSave.onLoading"><i class="fas fa-spinner fa-spin"></i> {{ tt('please_wait')
                                }}</span>
                            <span v-else>
                                <span v-if="form.add">{{ tt('add') }}</span>
                                <span v-else>{{ tt('edit') }}</span>
                            </span>
                        </base-button>
                    </template>
                </modal>
            </validation-observer>

            <!-- MODAL ADD CHARACTERISTIC VALUE -->
            <validation-observer v-slot="{ invalid }">
                <modal :show.sync="formCharValue.show">
                    <template slot="header">
                        <h5 class="modal-title">{{ formCharValue.title }}</h5>
                    </template>
                    <div>
                        <!-- <base-alert type="danger" v-if="errorMessage">
                            <p><strong>{{ errorMessage.message }}</strong></p>
                            <div v-if="errorMessage.data.length != 0">
                                <span v-for="message in errorMessage.data">
                                    {{ message[0] }}<br>
                                </span>
                            </div>
                        </base-alert> -->

                        <div v-if="formCharValue.add">
                            <label class="form-control-label">{{ tt('sample_answer') }} <span
                                    class="text-danger">*</span></label>
                            <base-input :name="tt('sample_answer')" :placeholder="tt('sample_answer')"
                                v-model="characteristicValue.characteristic_value" rules="required"></base-input>
                        </div>
                        <div v-else>
                            <label class="form-control-label">{{ tt('current_value') }} <span
                                    class="text-danger">*</span></label>
                            <base-input :name="tt('current_value')" :placeholder="tt('current_value')"
                                v-model="characteristicValue.current_value" readonly></base-input>

                            <label class="form-control-label">{{ tt('update_to') }} <span
                                    class="text-danger">*</span></label>
                            <base-input :name="tt('update_to')" :placeholder="tt('update_to')"
                                v-model="characteristicValue.characteristic_value" rules="required|max:95"></base-input>
                        </div>

                        <label class="form-control-label">{{ tt('abbreviation') }}</label>
                        <base-input :name="tt('abbreviation_to')" :placeholder="tt('abbreviation')"
                            v-model="characteristicValue.abbreviation"></base-input>

                        <label class="form-control-label">{{ tt('approval') }} <span
                                class="text-danger">*</span></label>
                        <base-input :name="tt('approval')" rules="required">
                            <el-select class="select-danger" v-model="characteristicValue.approved"
                                :placeholder="tt('choose_approval')">
                                <el-option class="select-danger" value="0" label="Not Approved"
                                    key="Not Approved"></el-option>
                                <el-option class="select-danger" value="1" label="Approved" key="Approved"></el-option>
                            </el-select>
                        </base-input>
                    </div>
                    <template slot="footer">
                        <base-button type="secondary" @click="formCharValue.show = false">{{ tt('close')
                            }}</base-button>
                        <base-button type="primary" v-on:click="saveCharValue"
                            :disabled="btnSaveCharValue.onLoading || invalid">
                            <span v-if="btnSaveCharValue.onLoading"><i class="fas fa-spinner fa-spin"></i> {{
                                tt('please_wait') }}</span>
                            <span v-else>
                                <span v-if="formCharValue.add">{{ tt('add') }}</span>
                                <span v-else>{{ tt('edit') }}</span>
                            </span>
                        </base-button>
                    </template>
                </modal>
            </validation-observer>

        </div>
        <noaccess v-else />
    </div>
</template>
<script>
import { mapState } from 'vuex'
import Api from '@/helpers/api';
import characteristicItemName from '@/services/dictionary/characteristicItemName.service';
import standardItemName from '@/services/dictionary/standardItemName.service';
import characteristicMaster from '@/services/dictionary/characteristicMaster.service';

export default {
    data() {
        return {
            itemTypeFilter: '',
            tes: 'X',
            showTable: false,
            onLoad: false,
            onLoadCharValue: false,
            loadTimeout: null,
            btnSave: {
                onLoading: false
            },
            btnSaveCharValue: {
                onLoading: false
            },
            btnAdd: false,
            btnEdit: false,
            btnEditCin: {
                onLoading: false
            },
            btnAddCharValue: false,
            searchCharValue: false,
            form: {
                add: true,
                title: this.tt('add_new_mcr'),
                show: false,
            },
            formCharValue: {
                add: true,
                title: this.tt('add_new_mcr'),
                show: false,
            },
            table: {
                search: '',
                total: 0,
                data: [],
                page: [],
            },
            tableCharValue: {
                search: '',
                total: 0,
                data: [],
                page: [],
            },
            errorMessage: null,
            links: [],
            characteristicItemName: {},
            standardItemName: {},
            characteristicMaster: [],
            selectCharacteristic: [],
            characteristicValue: {
                current_value: '',
                abbreviation: '',
                approved: '0',
            },
            currentRow: {
                index: null
            },
            inc: '',
            loading: false,
        }
    },
    computed: {
        ...mapState({
            authUserPermission: state => state.auth.userPermission
        }),
    },
    watch: {
        'itemTypeFilter': function () {
            this.get()
        }
    },
    mounted() {
        this.firstLoad()
    },
    methods: {
        handleCurrentChange(val, index) {
            this.currentRow = val;
            this.currentRow.index = index;
            this.btnAddCharValue = true;
            this.onLoadCharValue = true;
            this.getCharValue();
        },
        getCharValue() {
            let context = this;
            let search = '';
            let index = [];
            context.onLoadCharValue = true;

            Api(context, characteristicItemName.get_characteristic_value((this.tableCharValue.page.current_page ? this.tableCharValue.page.current_page : 1), context.currentRow.cin_code, { search_char_value: context.tableCharValue.search })).onSuccess(response => {
                this.tableCharValue.total = response.data.data.data.total;
                this.tableCharValue.data = response.data.data.data.data;
                this.tableCharValue.page = response.data.data.data;
            }).onError(function (error) {
                if (error.response.status == 404) {
                    if (error.response.data.message == 'characteristic Value Is Empty') {
                        context.errorMessage = null;
                        context.tableCharValue.data = []
                    } else if (error.response.data.message == 'Validation Error') {
                        context.errorMessage = error.response.data;
                    }
                }
            }).onFinish(function () {
                context.onLoadCharValue = false;
            }).call()
        },
        firstLoad() {
            this.onLoad = true;
            this.inc = this.$route.query.search
            this.table.search = this.$route.query.search

            if (this.$route.query.search) {
                this.btnAdd = true;
                this.btnEdit = true;
            }

            this.get()
        },
        get() {
            let context = this;

            Api(context, characteristicItemName.get(this.table.page.current_page, { search: this.inc, item_type_filter: this.itemTypeFilter })).onSuccess(function (response) {
                context.table.total = response.data.data.total;
                context.table.data = response.data.data.data;
                context.table.page = response.data.data;
                context.onLoad = false;
                context.showTable = true;
            }).onError(function (error) {
                if (error.response.status == 404) {
                    context.table.data = '';
                    context.table.data = [];
                    context.table.total = 0;
                    context.onLoad = true;
                    context.showTable = false;
                }
            }).onFinish(function () {
                context.tableCharValue.data = [];
            }).call()
        },
        changeItemType(type) {
            this.itemTypeFilter = type;
        },

        filter() {
            this.inc = this.table.search

            clearTimeout(this.loadTimeout);
            this.loadTimeout = setTimeout(() => {
                if (this.table.search == '') {
                    this.onLoad = true;
                    this.showTable = false;
                } else {
                    this.onLoad = true;
                    this.showTable = true;
                }
                this.btnAdd = true;
                this.btnEdit = true;
                this.btnAddCharValue = false;
                this.get()
            }, 100);

            this.$router.push({ path: '/dictionary/characteristic-item-name?search=' + this.inc })
        },
        filterCharValue() {
            clearTimeout(this.loadTimeout);
            this.loadTimeout = setTimeout(() => {
                this.getCharValue()
            }, 100);
        },
        handleTableAction(command) {
            switch (command.action) {
                case 'editCharValue':
                    this.editCharValue(command.data);
                    break;
                case 'removeCharValue':
                    this.removeCharValue(command.data);
                    break;
                default:
                    break;
            }
        },
        create() {
            this.errorMessage = null;
            this.form.add = true;
            this.form.title = this.tt('add_char_item_name');
            this.form.show = true;
        },
        createCharValue() {
            this.characteristicValue = {};
            this.formCharValue.add = true;
            this.formCharValue.title = this.tt('add_char_value');
            this.formCharValue.show = true;
            this.characteristicValue = {}
        },
        editCharValue(index) {
            let context = this;
            context.characteristicValue = {
                id: context.tableCharValue.data[index].id,
                current_value: context.tableCharValue.data[index].characteristic_value,
                current_char_value: context.tableCharValue.data[index].characteristic_value,
                characteristic_value: context.tableCharValue.data[index].characteristic_value,
                abbreviation: context.tableCharValue.data[index].abbreviation,
                approved: String(context.tableCharValue.data[index].approved),
            };
            context.formCharValue.add = false;
            context.formCharValue.title = context.tt('edit_characteristic_value');
            context.formCharValue.show = true;
        },
        update() {
            let context = this;
            context.confirmDialog(this.tt('confirm_update')).then((result) => {
                if (result.value) {
                    this.btnEditCin.onLoading = true;

                    let context = this;
                    let dataUpdate = {
                        cin_code: [],
                        sequence_char_item_name: [],
                        sequence_shortdes: [],
                        separator: [],
                        is_mandatory: [],
                    };

                    context.btnEdit = false;

                    for (var i = 0; i < context.table.data.length; i++) {
                        dataUpdate.cin_code[i] = context.table.data[i].cin_code;
                        dataUpdate.sequence_char_item_name[i] = context.table.data[i].sequence;
                        dataUpdate.sequence_shortdes[i] = context.table.data[i].sequence_shortdes;
                        dataUpdate.separator[i] = (context.table.data[i].separator_code == null) ? '' : context.table.data[i].separator_code;
                        dataUpdate.is_mandatory[i] = (context.table.data[i].is_mandatory == null) ? 'no' : context.table.data[i].is_mandatory;
                    }

                    Api(context, characteristicItemName.update_char_item_name(context.inc, dataUpdate)).onSuccess(function (response) {
                        context.get();
                        context.$notify({
                            message: response.data.message,
                            type: 'success',
                            verticalAlign: 'bottom',
                            horizontalAlign: 'left'
                        });
                    }).onFinish(function () {
                        context.btnEdit = true;
                        context.btnEditCin.onLoading = false
                    }).call()
                }
            })
        },
        save() {
            let context = this;
            let api = null;
            context.onLoad = true;
            context.btnSave.onLoading = true;

            context.characteristicItemName.inc = context.inc;
            if (context.form.add) {
                api = Api(context, characteristicItemName.add_char_item_name(context.characteristicItemName));
            } else {
                {
                    api = Api(context, characteristicItemName.update(context.characteristicItemName.id, context.characteristicItemName));
                }
            }

            api.onSuccess(function (response) {
                context.get();
                context.$notify({
                    message: response.data.message,
                    type: 'success',
                    verticalAlign: 'bottom',
                    horizontalAlign: 'left'
                });
                context.characteristicItemName = {};
                context.form.show = false;
            }).onError(function (error) {
                if (error.response.status == 404) {
                    if (error.response.data.message == 'Characteristic is Exists') {
                        context.$notify({
                            message: error.response.data.message,
                            type: 'danger',
                            verticalAlign: 'bottom',
                            horizontalAlign: 'left'
                        });
                        context.errorMessage = null;
                    } else if (error.response.data.message == 'Validation Error') {
                        context.errorMessage = error.response.data;
                    }
                } else if (error.response.status == 500) {
                    context.errorMessage = {
                        status: "Fail",
                        message: "Validation Error",
                        data: {
                            characteristic_code: [
                                "The inc field is required."
                            ]
                        }
                    };
                }
            }).onFinish(function () {
                context.onLoad = false;
                context.btnSave.onLoading = false;
            })
                .call();
        },
        saveCharValue() {
            let context = this;
            let api = null;
            context.btnSaveCharValue.onLoading = true;

            context.characteristicValue.cin_code = context.currentRow.cin_code;
            if (context.formCharValue.add) {
                api = Api(context, characteristicItemName.add_char_value(context.characteristicValue));
            } else {
                {
                    api = Api(context, characteristicItemName.update_char_value(context.characteristicValue.id, context.characteristicValue));
                }
            }
            api.onSuccess(function (response) {
                context.getCharValue();
                context.$notify({
                    message: response.data.message,
                    type: 'success',
                    verticalAlign: 'bottom',
                    horizontalAlign: 'left'
                });
                context.formCharValue.show = false;
            }).onError(function (error) {
                // context.errorMessage = error.response.data;
                if (error.response.status == 404) {
                    if (error.response.data.message == 'Characteristic Value is Exists') {
                        context.$notify({
                            message: error.response.data.message,
                            type: 'danger',
                            verticalAlign: 'bottom',
                            horizontalAlign: 'left'
                        });
                        context.errorMessage = null;
                    } else if (error.response.data.message == 'Validation Error') {
                        context.errorMessage = error.response.data;
                    }
                } else if (error.response.status == 500) {
                    context.errorMessage = {
                        status: "Fail",
                        message: "Validation Error",
                        data: {
                            characteristic_code: [
                                "The inc field is required."
                            ]
                        }
                    };
                }
            }).onFinish(function () {
                context.characteristicValue = {
                    current_value: '',
                    approved: '0',
                },
                    context.btnSaveCharValue.onLoading = false;
            })
                .call();
        },
        remove(id) {
            let context = this;
            context.confirmDialog(this.tt('confirm_delete')).then((result) => {
                if (result.value) {
                    context.onLoad = true;
                    Api(context, characteristicItemName.delete_char_item_name(id)).onSuccess(function (response) {
                        context.get();
                        context.$notify({
                            message: response.data.message,
                            type: 'success',
                            verticalAlign: 'bottom',
                            horizontalAlign: 'left'
                        });
                        context.onLoad = false;
                    }).onFinish(function () {
                        context.onLoad = false;
                    }).call();
                }
            })
        },
        removeCharValue(id) {
            let context = this;
            context.confirmDialog(this.tt('confirm_delete')).then((result) => {
                if (result.value) {
                    context.onLoadCharValue = true;
                    Api(context, characteristicItemName.delete_char_value(id)).onSuccess(function (response) {
                        context.$notify({
                            message: response.data.message,
                            type: 'success',
                            verticalAlign: 'bottom',
                            horizontalAlign: 'left'
                        });
                        context.getCharValue();
                        context.onLoadCharValue = false;
                    }).onFinish(function () {
                        context.onLoadCharValue = false;
                    }).call();
                }
            })
        },
        querySearchStandardItemName(query) {
            if (query.length > 3) {
                clearTimeout(this.loadTimeout);
                let context = this;

                this.loading = true;

                this.loadTimeout = setTimeout(() => {
                    Api(context, standardItemName.get(null, { per_page: 'none', item_name: query })).onSuccess(function (response) {
                        context.standardItemName = response.data.data.data.data;
                        context.loading = false;
                    }).onError(function (error) {
                        context.standardItemName = [];
                        context.loading = false;
                    }).call();
                }, 200)
            }
        },

        // getCharacteristicMaster() {
        //     let context = this;
        //     Api(context, characteristicMaster.get(null, { per_page: 'none' })).onSuccess(function (response) {
        //         response.data.data.data.data.forEach(function (item, index) {
        //             context.characteristicMaster[index] = {
        //                 'code': item['characteristic_code'],
        //                 'value': item['characteristic'] + ' (' + item['item_type_code'] + ')'
        //             };
        //         });
        //     }).onError(function (error) {
        //         if (error.response.status == 404) {
        //             context.characteristicMaster = [];
        //         }
        //     })
        //         .call()
        // },

        // querySearchCharacteristicMaster(queryString, cb) {
        //     var links = this.characteristicMaster;
        //     var results = queryString ? links.filter(this.createFilter(queryString)) : links;
        //     cb(results);
        // },

        querySearchCharacteristicMaster(query) {
            if (query.length > 2) {
                clearTimeout(this.loadTimeout);
                let context = this;

                this.loading = true;

                this.loadTimeout = setTimeout(() => {
                    Api(context, characteristicMaster.getCharacteristicByType(null, { per_page: 'none', search: query, type_code: context.itemTypeFilter })).onSuccess(function (response) {
                        context.selectCharacteristic = response.data.data.data.data;
                        context.loading = false;
                    }).onError(function (error) {
                        context.selectCharacteristic = [];
                        context.loading = false;
                    }).call();
                }, 200)
            } else {
                this.selectCharacteristic = [];
            }
        },

        createFilter(queryString) {
            return (link) => {
                return (link.value.toLowerCase().indexOf(queryString.toLowerCase()) > -1);
            };
        },
        loadAll(materialType) {
            return materialType;
        },
        changePage(page) {
            let context = this;
            context.onLoad = true;

            clearTimeout(this.loadTimeout);
            this.loadTimeout = setTimeout(() => {
                this.get()
            }, 100);
        },
        changePageCharValue(page) {
            let context = this;
            context.onLoadCharValue = true;

            clearTimeout(this.loadTimeout);
            this.loadTimeout = setTimeout(() => {
                this.getCharValue()
            }, 100);
        }
    }
};
</script>
<style>
.selected-row {
    background-color: #ecf5ff;
    color: white;
}

.filter.mb--1:hover {
    cursor: pointer;
}

.el-table__body tr.current-row>td {
    background-color: #ffe391 !important;
}
</style>
